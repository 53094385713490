<template>
  <vx-card title="Invoice Exchange Creation">
    <div class="flex flex-col gap-5 mt-4">
      <div class="flex flex-col gap-4">
        <vx-card :key="index" v-for="(record, index) in responseData">
          <div class="flex flex-col gap-6">
            <div class="flex flex-row gap-20">
              <div class="flex flex-col gap-8">
                <div class="flex flex-col">
                  <h5>Customer Code</h5>
                  <span> {{ record.customer.code }}</span>
                </div>
                <div class="flex flex-col">
                  <h5 class="text-bold">Customer Name</h5>
                  <span> {{ record.customer.name }}</span>
                </div>
              </div>

              <div class="flex flex-col gap-8">
                <div class="flex flex-col">
                  <h5>Ship To Code</h5>
                  <span>
                    {{
                      record.customer_address.code
                        ? record.customer_address.code
                        : "-"
                    }}</span
                  >
                </div>
                <div class="flex flex-col">
                  <h6 class="text-bold">Ship To Name</h6>
                  <span> {{ record.customer_address.contact_name }}</span>
                </div>
              </div>

              <div class="flex flex-col gap-8">
                <div class="flex flex-col">
                  <h6 class="text-bold">Ship To Address</h6>
                  <span>
                    <!-- {{
                      "${record.customer_address.address}, ${record.customer_address.sub_district}, ${record.customer_address.sub_district}, ${record.customer_address.city}""
                    }} -->
                    {{ record.customer_address.address }},
                    {{ record.customer_address.sub_district }}, <br />
                    {{ record.customer_address.sub_district }},
                    {{ record.customer_address.city }}
                  </span>
                </div>
                <!-- <div class="flex flex-col">
                        <h6>Ship To Code</h6>
                        <span> {{ record.customer_address.code }}</span>
                      </div> -->
              </div>
            </div>

            <div
              class="flex flex-col gap-3 w-1/2 mt-6"
              style="min-width: 300px"
            >
              <h6>Schedule</h6>
              <schedule-display :schedules="record.exchange.schedules" />
            </div>

            <div class="flex flex-col gap-3 w-1/2" style="min-width: 300px">
              <h6>Nomor Tukar Faktur</h6>
              <vs-input
                v-model="exchangeModels[index].invoice_exchange_number"
                class="w-full"
              />
            </div>

            <div class="flex flex-col gap-3 w-1/2" style="min-width: 300px">
              <h6>Ship to Name</h6>
              <vs-input
                v-model="exchangeModels[index].distribution_channel"
                class="w-full"
                :disabled="true"
              />
            </div>

            <!-- <div class="flex flex-col gap-3 w-1/2" style="min-width: 300px">
              <h6>Reference Code</h6>
              <vs-input
                v-model="exchangeModels[index].ref_code"
                class="w-full"
              />
            </div> -->

            <div class="flex flex-col gap-4 w-1/2" style="min-width: 300px">
              <h6>Tanggal Tukar Faktur</h6>
              <datepicker
                class="w-full"
                v-validate="'required'"
                :inline="false"
                name="Date Reversal"
                v-model="exchangeModels[index].date"
                placeholder="-"
              ></datepicker>
            </div>
            <div class="flex flex-col gap-3 w-1/2" style="min-width: 300px">
              <h6>Attachment</h6>
              <div class="flex justify-between items-center gap-3">
                <vs-input
                  type="file"
                  class="w-full"
                  v-model="exchangeModels[index].file_name"
                  @change="onFileChange($event, index)"
                  multiple
                />
                <vs-button
                  color="danger"
                  icon-pack="feather"
                  icon="icon-x"
                  @click="resetFile"
                ></vs-button>
              </div>
            </div>
          </div>

          <vs-collapse>
            <vs-collapse-item :open="true">
              <div class="w-full" style="max-height: 300px; overflow: auto">
                <vs-table :data="record.invoices">
                  <template slot="thead">
                    <vs-th> Invoice </vs-th>
                    <vs-th> Customer GR Number </vs-th>
                    <vs-th> Supplier Code </vs-th>
                  </template>

                  <template>
                    <vs-tr :key="i" v-for="(invoice, i) in record.invoices">
                      <vs-td>
                        Code : {{ invoice.Code }}
                        <br />
                        Faktur : {{ invoice.FakturNumber }}
                        <br />
                        Date : {{ dateFormat(invoice.Date) }}
                        <br />
                        Due Date : {{ dateFormat(invoice.DueDate) }}
                        <br />
                        Posting Date : {{ dateFormat(invoice.PostingDate) }}
                        <br />
                        Total : {{ priceFormat(invoice.Total) }}
                      </vs-td>

                      <vs-td>
                        <vs-input
                          v-model="exchangeModels[index].invoices[i].gr_number"
                          class="w-full"
                        />
                      </vs-td>

                      <vs-td>
                        <vs-input
                          v-model="
                            exchangeModels[index].invoices[i].supplier_code
                          "
                          class="w-full"
                        />
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
            </vs-collapse-item>
          </vs-collapse>
        </vx-card>
      </div>
      <!-- <vs-divider />
      <div class="flex flex-col gap-4">
        <h6>Invoice Exchanges Schedule</h6>
        <data-table
          v-if="isShow"
          :responseData="responseData2"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="tbody">
            <vs-tr
              :data="data"
              :key="index"
              v-for="(data, index) in responseData2.customer_invoice_exchanges"
            >
              <vs-td class="whitespace-no-wrap" style="width: 70px">
                <vs-radio
                  v-model="selectedSchedule"
                  :vs-value="data.ID"
                ></vs-radio>
              </vs-td>
              <vs-td :data="data.customer_name">{{ data.customer_name }}</vs-td>
              <vs-td :data="data.sold_to_code">{{ data.sold_to_code }}</vs-td>
              <vs-td :data="data.sold_to_address">{{
                data.sold_to_address
              }}</vs-td>
              <vs-td :data="data.schedules"
                ><schedule-display :schedules="data.schedules"
              /></vs-td>
            </vs-tr>
          </template>
        </data-table> -->
      <!-- </div> -->
      <div class="flex gap-3 mt-4">
        <vs-button color="danger" @click="navigateBack">Close</vs-button>
        <vs-button @click="createInvoiceExchange">Create</vs-button>
      </div>
    </div>
  </vx-card>
</template>
<script>
import moment from "moment";
import scheduleDisplay from "./schedule-display.vue";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    "schedule-display": scheduleDisplay,
    datepicker: Datepicker,
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
      },
      header: [
        {
          text: "Select One",
        },
        {
          text: "Customer",
          value: "customer_code",
        },
        {
          text: "Sold To Code",
          value: "sold_to_code",
        },
        {
          text: "Address",
        },
        {
          text: "Schedules",
        },
      ],
      responseData: [],
      responseData2: {},
      selectedSchedule: null,
      invoices: [],
      invoiceModels: [],
      invoiceIDs: [],
      file: null,
      isShow: false,
      exchangeModels: [],
      invoice_exchange_number: "",
      distribution_channel: "",
    };
  },
  mounted() {
    this.invoiceIDs = JSON.parse(atob(this.$route.params.ids));
    // this.invoiceIDs.sort((a, b) => b - a);
    // this.invoiceIDs.forEach((id) => {
    //   this.invoiceModels.push({
    //     id: id,
    //     gr_number: "",
    //     supplier_code: "",
    //     ref_code: "",
    //   });
    // });

    this.getInvoice();
  },
  methods: {
    dateFormat(value) {
      return moment(value).format("DD-MM-YYYY");
    },
    apiDateFormat(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    reloadData(params) {
      //   this.params = {
      //     ...params,
      //     territory_ids:
      //       this.$store.getters["operatingUnit/getSelectedTerritoryId"],
      //   };
      this.params = params;
      console.log("params", this.params);
      this.$vs.loading();

      const dataTable = this.datatable(this.params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code > 299) {
          this.$vs.loading.close();
          this.$vs.notify({
            position: "top-right",
            title: "Error",
            text: r.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        } else if (r.code == 200) {
          this.$vs.loading.close();
          // this.data = r.data.payment;
          this.responseData2 = r.data;
          console.log("r.data", r.data);
          // this.responseData.length = r.data.payment.length;
          this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    mounted() {
      this.getInvoice();
    },
    datatable(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .get("/api/v1/customer-invoice-exchange/data-table", {
            params: {
              search: params.search,
              length: params.length,
              page: params.page,
              order: params.order,
              sort: params.sort,
              status: 1,
            },
          })
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    navigateBack() {
      this.$router.push({ name: "Invoice Exchange" });
    },
    validateInput() {
      let validated = false;

      for (const data of this.exchangeModels) {
        if (data.invoice_exchange_number.trim() === "") {
          this.$vs.notify({
            title: "Invoice exchange creation",
            text: "Nomor tukar faktur is required",
            position: "top-right",
            color: "danger",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return validated;
        }

        if (data.distribution_channel.trim() === "") {
          this.$vs.notify({
            title: "Invoice exchange creation",
            text: "Ship to name is required",
            position: "top-right",
            color: "danger",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return validated;
        }

        // if (data.ref_code.trim() === "") {
        //   this.$vs.notify({
        //     title: "Invoice exchange creation",
        //     text: "Please fill reference code",
        //     position: "top-right",
        //     color: "danger",
        //     iconPack: "feather",
        //     icon: "icon-x-circle",
        //   });
        //   return validated;
        // }

        if (data.date === "") {
          this.$vs.notify({
            title: "Invoice exchange creation",
            text: "Tanggal tukar faktur is required",
            position: "top-right",
            color: "danger",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return validated;
        }

        if (!data.file || data.file.length === 0) {
          this.$vs.notify({
            title: "Invoice exchange creation",
            text: "Please select attachment",
            position: "top-right",
            color: "danger",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return validated;
        }

        for (const invoice of data.invoices) {
          const tempGR = invoice.gr_number.trim();
          const tempSupplier = invoice.supplier_code.trim();
          if (tempGR === "" || tempSupplier === "") {
            this.$vs.notify({
              title: "Invoice exchange creation",
              text: "Please fill all Customer GR Number and Supplier Code",
              position: "top-right",
              color: "danger",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            return validated;
          }
        }
      }
      validated = true;
      return validated;
    },
    async createInvoiceExchange() {
      try {
        if (!this.validateInput()) {
          return;
        }
        this.$vs.loading();
        const requestData = [];
        const form = new FormData();
        for (let i = 0; i < this.responseData.length; i++) {
          const invoiceExchange = {
            customer_invoice_exchange_id: this.responseData[i].exchange.ID,
            lines: this.exchangeModels[i].invoices,
            territory_id: this.responseData[i].invoices[0].TerritoryID,
            territory_area_id: this.responseData[i].invoices[0].TerritoryAreaID,
            date: this.apiDateFormat(this.exchangeModels[i].date),
            reference_code: this.exchangeModels[i].ref_code,
            invoice_exchange_number:
              this.exchangeModels[i].invoice_exchange_number,
            distribution_channel: this.exchangeModels[i].distribution_channel,
          };
          requestData.push(invoiceExchange);
        }

        for (let i = 0; i < this.exchangeModels.length; i++) {
          for (let j = 0; j < this.exchangeModels[i].file.length; j++) {
            form.append("files", this.exchangeModels[i].file[j]);
          }
        }

        const finalData = { invoice_exchange_data: requestData };
        form.append("data", JSON.stringify(finalData));

        for (var pair of form.entries()) {
          console.log(pair[0] + ", " + pair[1]);
        }

        let resp = await this.postInvoiceExchangeData(form);
        if (resp.code > 299) {
          throw new Error(resp.message);
        }

        this.$vs.loading.close();
        this.$router.push({ name: "Invoice Exchange" });
        this.$vs.notify({
          title: "Success",
          text: "Invoice exchange has been created",
          position: "top-right",
          color: "success",
          iconPack: "feather",
          icon: "icon-check-circle",
        });
      } catch (e) {
        console.log(e);
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.message,
          position: "top-right",
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    onFileChange(e, index) {
      this.exchangeModels[index].file = e.target.files;
    },
    getInvoice() {
      this.$vs.loading();
      this.$http
        .get("api/v1/invoice/exchange", {
          params: {
            ids: this.invoiceIDs,
            status_in: [1, 2, 3],
            sort: "id",
            order: "desc",
          },
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 500) {
            this.$vs.loading.close();
            if (resp.message.includes("record not found")) {
              this.$vs.notify({
                title: "Error",
                text: "Customer invoice exchange for some customer is not found, please create customer invoice exchange first",
                position: "top-right",
                color: "danger",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
              this.$router.push({ name: "Invoice Exchange" });
            } else {
              this.$vs.notify({
                title: "Error",
                text: resp.message,
                position: "top-right",
                color: "danger",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          } else if (resp.code == 200) {
            this.$vs.loading.close();
            this.responseData = resp.data;
            console.log(this.responseData);
            this.responseData.forEach((data, index) => {
              const temp = [];
              data.invoices.forEach((invoice) => {
                temp.push({
                  id: invoice.ID,
                  gr_number: "",
                  supplier_code: "",
                });
              });

              // this.invoiceModels.push({
              //   id: index + 1,
              //   lines: temp,
              // });

              const model = {
                id: index + 1,
                date: new Date(),
                ref_code: "",
                invoice_exchange_number: "",
                distribution_channel: `${
                  data.customer.name
                } - ${this.getTerritory(data.customer_address.territory_id)}`,
                file_name: "",
                file: [],
                invoices: temp,
              };

              this.exchangeModels.push(model);
            });

            console.log("models", JSON.stringify(this.exchangeModels));
            // this.checkedAll = false;
            this.isShow = true;
          } else {
            this.$vs.loading.close();
          }
        });
    },
    resetFile() {
      this.file = null;
    },
    postInvoiceExchangeData(data) {
      return this.$http.post("/api/v1/invoice-exchange", data);
    },
    postAttachment(file) {
      return this.$http.post("/api/v1/invoice-exchange-attachment", file);
    },
    getTerritory(territoryID) {
      const territory = [...this.$store.state.operatingUnit.filter.territory];
      const territoryName = territory.find(
        (v) => v.TerritoryID === territoryID
      );
      return territoryName ? territoryName.TerritoryName : "";
    },
  },
};
</script>
